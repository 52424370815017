<template>
  <div v-if="permission.access">
    <b-card :title="course.name">
      <b-tabs pills>
        <b-tab title="Basic Information">
          <b-card-text>
            <BasicInformation :course_from="course_from" v-if="course" />
          </b-card-text>
        </b-tab>
        <b-tab title="Media">
          <b-card-text>
            <Media v-if="course" :course_from="course_from" />
          </b-card-text>
        </b-tab>
        <b-tab title="CMS">
          <b-card-text>
            <Cms
              v-if="course"
              :id="course.id"
              :course_profile="course_profile"
            />
          </b-card-text>
        </b-tab>

        <b-tab title="Topic & Class Manage" active>
          <b-card-text>
            <TopicAndClassVue />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCardText, BCard } from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
import BasicInformation from "./partials/BasicInformation.vue";
import TopicAndClassVue from "./partials/TopicAndClass.vue";
import Cms from "./partials/Cms.vue";
import Media from "./partials/Media.vue";

export default {
  data() {
    return {
      course_from: {
        id: this.$route.params.id,
        category_id: null,
        teacher_id: null,
        slug: null,
        name: null,
        course_fee: null,
        discount: null,
        teacher_commission: null,
        affiliate_commission: null,
        course_details_array: [],
        level: null,
        type: null,
        activities: null,
        duration: null,
        description: null,
        review: null,
        rating: null,
        status: false,
        is_show: false,
        video_id: "",
        image: null,
        video_type: null,
      },
      course: {},
      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
      course_profile: null,
    };
  },
  mounted() {
    this.Permission();
    this.getResults();
    this.$store.dispatch("getSubCategory");
    this.$store.dispatch("getTeacher");
  },
  methods: {
    async getResults() {
      const course = await this.callApi(
        "post",
        "/app/course/show/" + this.$route.params.id
      );
      if (course.status == 200) {
        this.course = course.data.course;
        this.course_from.id = this.course.id;
        this.course_from.category_id = this.course.category_id;
        this.course_from.teacher_id = this.course.teacher_id;
        this.course_from.name = this.course.name;
        this.course_from.slug = this.course.slug;
        this.course_from.course_fee = this.course.course_fee;
        this.course_from.discount = this.course.discount;
        this.course_from.teacher_commission = this.course.teacher_commission;
        this.course_from.affiliate_commission =
          this.course.affiliate_commission;
        this.course_from.level = this.course.level;
        this.course_from.type = this.course.type;
        this.course_from.activities = this.course.activities;
        this.course_from.duration = this.course.duration;
        this.course_from.description = this.course.description;
        this.course_from.review = this.course.review;
        this.course_from.rating = this.course.rating;
        if (this.course.course_details_array) {
          this.course_from.course_details_array =
            this.course.course_details_array;
        } else {
          this.course_from.course_details_array.push({ data: null });
        }
        this.course_from.is_show = this.course.is_show == true ? true : false;
        this.course_from.status = this.course.status == true ? true : false;
        // Need Change
        this.course_from.image = this.course.image
          ? this.$store.state.base_url + this.course.image
          : this.getImage(1116, 728);
        this.course_from.video_id = this.course.video_id;
        this.course_from.video_type = this.course.video_type;
      }
    },
    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "course.access") {
          this.permission.access = true;
        }

        if (permission.slug == "course.create") {
          this.permission.create = true;
        }

        if (permission.slug == "course.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "course.destroy") {
          this.permission.delete = true;
        }
      });
    },
    async getProfileResults() {
      const course_profile = await this.callApi(
        "post",
        "/app/course/profile/show/" + this.$route.params.id
      );
      if (course_profile.status == 200) {
        this.course_profile = course_profile.data;
      }
    },
  },
  watch: {},
  components: {
    vSelect,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BasicInformation,
    TopicAndClassVue,
    Cms,
    Media,
  },
  computed: {
    categories() {
      return this.$store.getters.getSubCategory;
    },
    teachers() {
      return this.$store.getters.getTeacher;
    },
  },
};
</script>
