<template>
  <div class="mt-2 p-1">
    <div>
      <validation-observer ref="loginValidation">
        <b-form
          class="auth-login-form"
          @submit.prevent
          enctype="multipart/form-data"
        >
          <div class="row mb-2">
            <div class="col-md-8">
              <b-form-group label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="Topic Name"
                  rules="required"
                >
                  <Input
                    name="name"
                    type="text"
                    v-model="add_topic.name"
                    id="name"
                    placeholder="Enter Topic Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-4 text-right">
              <b-form-checkbox
                name="status"
                style="margin-top: 5px"
                v-model="add_topic.status"
                switch
                inline
              >
                Status
              </b-form-checkbox>
              <b-form-checkbox
                name="is_unlock"
                style="margin-top: 5px"
                v-model="add_topic.is_unlock"
                switch
                inline
              >
                Is Unlock
              </b-form-checkbox>
              <b-button
                type="submit"
                class="float-right mx-1"
                variant="outline-primary"
                @click="Close"
              >
                Close
              </b-button>
              <b-button
                type="submit"
                class="float-right"
                variant="primary"
                @click="validationForm"
              >
                {{
                  add_topic.id == null || add_topic.id == "" ? "Add" : "Update"
                }}
              </b-button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
    <div>
      <draggable
        @change="rearrangeTopic"
        v-model="course_topics"
        class="list-group list-group-flush cursor-move"
        tag="ul"
      >
        <transition-group type="transition" name="flip-list">
          <b-list-group-item
            v-for="topic in course_topics"
            :key="topic.id"
            tag="li"
          >
            <div class="row">
              <div class="col-md-6">
                <b-card-text class="font-weight-bold mb-0">
                  <b>{{ topic.order_by }} . {{ topic.name }}</b>
                </b-card-text>
              </div>
              <div class="col-md-3">
                <b-card-text>
                  <b-card-text>
                    <span
                      class="badge mr-1"
                      :class="
                        topic.status == true ? 'badge-success' : 'badge-danger'
                      "
                      >{{ topic.status == true ? "Active" : "Inactive" }}</span
                    >
                    <span
                      class="badge"
                      :class="
                        topic.is_unlock == true
                          ? 'badge-primary'
                          : 'badge-success'
                      "
                      >{{ topic.is_unlock == true ? "Unlock" : "Lock" }}</span
                    >
                  </b-card-text>
                </b-card-text>
              </div>
              <div class="col-md-3 text-right">
                <b-card-text>
                  <b-card-text>
                    <button
                      class="btn btn-primary btn-sm mr-1"
                      @click="edit(topic.id)"
                    >
                      <feather-icon icon="EditIcon" />
                    </button>
                    <button
                      class="btn btn-success btn-sm"
                      @click="getLessonModel(topic.id)"
                    >
                      <feather-icon size="16" icon="PlusIcon" />
                    </button>
                  </b-card-text>
                </b-card-text>
              </div>
            </div>
          </b-list-group-item>
        </transition-group>
      </draggable>
    </div>

    <Modal
      title="Lesson & Quiz Manage"
      v-model="course_lesson_module"
      :mask-closable="false"
      :footer-hide="true"
      width="1200px"
    >
      <validation-observer ref="lessonValidation">
        <b-form
          class="auth-login-form"
          @submit.prevent
          enctype="multipart/form-data"
        >
          <div class="row mb-2">
            <div class="col-md-3">
              <b-form-group label-for="type">
                <validation-provider
                  #default="{ errors }"
                  name="Lesson Type"
                  rules="required"
                >
                  <Select
                    v-model="add_lesson.type"
                    filterable
                    name="type"
                    id="type"
                    placeholder="Select Lesson Type"
                  >
                    <Option :value="1">Lesson</Option>
                    <Option :value="2">Quiz</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="Lesson Name"
                  rules="required"
                >
                  <Input
                    name="name"
                    type="text"
                    v-model="add_lesson.name"
                    id="name"
                    placeholder="Enter Lesson Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group label-for="video_type">
                <validation-provider
                  #default="{ errors }"
                  name="Video Type"
                  :rules="add_lesson.type != 2 ? 'required' : ''"
                >
                  <Select
                    :disabled="add_lesson.type == 2"
                    v-model="add_lesson.video_type"
                    filterable
                    name="video_type"
                    id="video_type"
                    placeholder="Select Video Type"
                  >
                    <Option :value="1">Vimeo</Option>
                    <Option :value="2">Youtube</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-4" v-if="add_lesson.type != 2">
              <b-form-group label-for="video_id">
                <validation-provider
                  #default="{ errors }"
                  name="Video ID"
                  :rules="add_lesson.type != 2 ? 'required' : ''"
                >
                  <Input
                    :disabled="add_lesson.type == 2"
                    name="video_id"
                    type="text"
                    v-model="add_lesson.video_id"
                    id="video_id"
                    placeholder="Enter Video ID"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-3" v-if="add_lesson.type != 2">
              <b-form-group label-for="duration">
                <validation-provider
                  #default="{ errors }"
                  name="Video Duration"
                  :rules="add_lesson.type != 2 ? 'required' : ''"
                >
                  <InputNumber
                    :disabled="add_lesson.type == 2"
                    v-model="add_lesson.duration"
                    :min="0"
                    class="w-100"
                    id="duration"
                    name="duration"
                    placeholder="Enter Video Duration (Second)"
                  ></InputNumber>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div class="col-md-3" v-if="add_lesson.type == 2">
              <b-form-group label-for="question_qty">
                <validation-provider
                  #default="{ errors }"
                  name="Number Of Question"
                  :rules="add_lesson.type == 2 ? 'required' : ''"
                >
                  <InputNumber
                    :disabled="add_lesson.type == 1"
                    v-model="add_lesson.question_qty"
                    :min="0"
                    class="w-100"
                    id="question_qty"
                    name="question_qty"
                    placeholder="Enter Number Of Question"
                  ></InputNumber>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div class="col-md-4" v-if="add_lesson.type == 2">
              <b-form-group label-for="pass_percentage">
                <validation-provider
                  #default="{ errors }"
                  name="Number Passing Percentage"
                  :rules="add_lesson.type == 2 ? 'required' : ''"
                >
                  <InputNumber
                    :disabled="add_lesson.type == 1"
                    v-model="add_lesson.pass_percentage"
                    :min="0"
                    :max="100"
                    class="w-100"
                    id="pass_percentage"
                    name="pass_percentage"
                    placeholder="Enter Number Of Passing Percentage"
                  ></InputNumber>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-5 text-right">
              <b-form-checkbox
                name="status"
                style="margin-top: 5px"
                v-model="add_lesson.status"
                switch
                inline
              >
                Status
              </b-form-checkbox>
              <b-form-checkbox
                name="is_unlock"
                style="margin-top: 5px"
                v-model="add_lesson.is_unlock"
                switch
                inline
              >
                Is Unlock
              </b-form-checkbox>
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                @click="validationCourseLesson"
              >
                {{
                  add_lesson.id == null || add_lesson.id == ""
                    ? "Add"
                    : "Update"
                }}
              </b-button>
              <b-button
                type="submit"
                variant="outline-primary"
                @click="CloseLesson"
              >
                Close
              </b-button>
            </div>
          </div>
        </b-form>
      </validation-observer>
      <div>
        <draggable
          @change="rearrangeLesson"
          v-model="course_lessons"
          class="list-group list-group-flush cursor-move"
          tag="ul"
        >
          <transition-group type="transition" name="flip-list">
            <b-list-group-item
              v-for="lesson in course_lessons"
              :key="lesson.id"
              tag="li"
            >
              <div class="row align-items-center">
                <div class="col-md-6">
                  <b-card-text class="font-weight-bold mb-0">
                    <p class="mb-0" style="padding-bottom: 5px">
                      <b>{{ lesson.order_by }} . {{ lesson.name }}</b>
                    </p>
                    <p class="mb-0">
                      <span
                        class="badge"
                        style="margin-right: 5px"
                        :class="
                          lesson.type == 1 ? 'badge-primary' : 'badge-warning'
                        "
                        >{{ lesson.type == 1 ? "Lesson" : "Quiz" }}</span
                      >
                      <span
                        class="badge"
                        style="margin-right: 5px"
                        :class="
                          lesson.status == true
                            ? 'badge-success'
                            : 'badge-danger'
                        "
                        >{{
                          lesson.status == true ? "Active" : "Inactive"
                        }}</span
                      >
                      <span
                        class="badge"
                        :class="
                          lesson.is_unlock == true
                            ? 'badge-primary'
                            : 'badge-success'
                        "
                        >{{
                          lesson.is_unlock == true ? "Unlock" : "Lock"
                        }}</span
                      >
                    </p>
                  </b-card-text>
                </div>
                <div class="col-md-4">
                  <b-card-text>
                    <div v-if="lesson.type == 1">
                      <b
                        >{{ lesson.video_type == 1 ? "Vimeo" : "Youtube" }}
                        Video Id :
                      </b>
                      {{ lesson.video_id }} ({{ getDuration(lesson.duration) }})
                    </div>
                    <div v-else>
                      <b>Quiz : </b> {{ lesson.question_qty }} Qty. (Passing
                      {{ lesson.pass_percentage }}%)
                    </div>
                  </b-card-text>
                </div>
                <div class="col-md-2 text-right">
                  <b-card-text>
                    <div class="btn-group">
                      <button
                        class="btn btn-primary btn-sm"
                        @click="editLesson(lesson.id)"
                      >
                        <feather-icon icon="EditIcon" />
                      </button>
                      <button
                        class="btn btn-success btn-sm"
                        v-if="lesson.type == 1"
                      >
                        <feather-icon icon="EyeIcon" />
                      </button>
                      <button
                        @click="getQuiz(lesson.id)"
                        class="btn btn-success btn-sm"
                        v-if="lesson.type == 2"
                      >
                        <feather-icon icon="PlusIcon" />
                      </button>
                      <button
                        @click="getResourceModel(lesson.id)"
                        class="btn btn-success btn-sm"
                        v-if="lesson.type == 1"
                      >
                        <feather-icon icon="PlusIcon" />
                      </button>
                    </div>
                  </b-card-text>
                </div>
              </div>
            </b-list-group-item>
          </transition-group>
        </draggable>
      </div>
    </Modal>

    <Modal
      title="Add Quiz Question"
      v-model="quiz_question_model"
      :mask-closable="false"
      :footer-hide="true"
      width="1200px"
    >
      <form @submit.prevent="AddQuestion" @keydown="form.onKeydown($event)">
        <div class="row justify-content-end">
          <div class="col-md-8">
            <div class="form-group">
              <label for="question">Question</label>
              <Input
                :class="{
                  'is-invalid border-danger': form.errors.has('question'),
                }"
                name="question"
                type="text"
                v-model="form.question"
                id="question"
                placeholder="Enter Question"
              />
              <has-error :form="form" field="question"></has-error>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="answer">Select Answer</label>
              <Select
                v-model="form.answer"
                filterable
                :class="{
                  'is-invalid border-danger': form.errors.has('answer'),
                }"
                name="answer"
                id="answer"
                placeholder="Select Answer"
              >
                <Option
                  :value="option.number"
                  v-for="(option, index) in form.options"
                  :key="index"
                  v-if="option.name != null"
                  >{{ option.name }}</Option
                >
              </Select>
              <has-error :form="form" field="answer"></has-error>
            </div>
          </div>
          <div
            class="col-md-3"
            v-for="(option, index) in form.options"
            :key="index"
          >
            <div class="form-group">
              <label :for="'option' + option.number"
                >Option {{ option.number }}</label
              >
              <Input
                :name="'question' + option.number"
                type="text"
                v-model="option.name"
                id="question"
                :placeholder="'Enter Option ' + option.number"
              />
            </div>
          </div>
          <div class="col-md-4 d-flex justify-content-between">
            <b-form-checkbox
              name="status"
              style="margin-top: 12px"
              v-model="form.status"
              switch
              inline
            >
              Status
            </b-form-checkbox>
            <div>
              <b-button
                type="button"
                class="mr-2"
                variant="danger"
                @click="CloseQuestion()"
              >
                Close
              </b-button>
              <b-button type="submit" variant="primary" :disabled="form.busy">
                {{ form.id == null ? "Add" : "Update" }}
              </b-button>
            </div>
          </div>
        </div>
      </form>
      <div class="mt-5">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="text-center align-middle">SL</th>
                <th>Question</th>
                <th class="text-center align-middle">Status</th>
                <th class="text-center align-middle">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(question, index) in questions"
                :key="index"
                v-if="questions.length"
              >
                <td class="text-center align-middle">{{ index + 1 }}</td>
                <th class="align-middle">{{ question.question }}</th>
                <th class="text-center align-middle">
                  <span
                    class="badge"
                    :class="
                      question.status == true ? 'badge-success' : 'badge-danger'
                    "
                    >{{ question.status == true ? "Active" : "Inactive" }}</span
                  >
                </th>
                <td class="text-center align-middle">
                  <button
                    class="btn btn-primary btn-sm"
                    @click="editQuestion(question.id)"
                  >
                    <feather-icon icon="EditIcon" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Modal>

    <Modal
      title="Class Resource Manage"
      v-model="calss_resouce_model"
      :mask-closable="false"
      :footer-hide="true"
      width="1200px"
    >
      <form @submit.prevent="addClassResouce" @keydown="form.onKeydown($event)">
        <div class="row justify-content-end align-items-end">
          <div class="col-md-5">
            <div class="form-group">
              <label for="name">Resource Name</label>
              <Input
                :class="{
                  'is-invalid border-danger': form.errors.has('name'),
                }"
                name="name"
                type="text"
                v-model="classResource.name"
                id="name"
                placeholder="Enter Resource Name"
              />
              <has-error :form="classResource" field="name"></has-error>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="name">Select Resource PDF</label>
              <b-form-file
                id="icon"
                accept=".pdf"
                @change="loadFile($event)"
                placeholder="Select Resource PDF"
                drop-placeholder="Drop file here..."
              />
              <has-error :form="classResource" field="file"></has-error>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group d-flex justify-content-between">
              <b-form-checkbox
                name="status"
                style="margin-top: 12px"
                v-model="classResource.status"
                switch
                inline
              >
                Status
              </b-form-checkbox>
              <div>
                <b-button
                  type="button"
                  class="mr-2"
                  variant="danger"
                  @click="ClearResouce()"
                >
                  Clear
                </b-button>
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="classResource.busy"
                >
                  {{ classResource.id == null ? "Add" : "Update" }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="mt-5">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="text-center align-middle">SL</th>
                <th>Name</th>
                <th class="text-center align-middle">Resouce Link</th>
                <th class="text-center align-middle">Status</th>
                <th class="text-center align-middle">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(resource, index) in resources"
                :key="index"
                v-if="resources.length"
              >
                <td class="text-center align-middle">{{ index + 1 }}</td>
                <th class="align-middle">{{ resource.name }}</th>
                <th class="align-middle">
                  <a
                    :href="$store.state.base_url + resource.file"
                    target="_blank"
                    >{{ resource.file }}</a
                  >
                </th>
                <th class="text-center align-middle">
                  <span
                    class="badge"
                    :class="
                      resource.status == true ? 'badge-success' : 'badge-danger'
                    "
                    >{{ resource.status == true ? "Active" : "Inactive" }}</span
                  >
                </th>

                <td class="text-center align-middle">
                  <button
                    class="btn btn-primary btn-sm"
                    @click="editResource(resource)"
                  >
                    <feather-icon icon="EditIcon" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Button, HasError, AlertError } from "vform/src/components/bootstrap5";
import { required } from "@validations";
import {
  BListGroupItem,
  BAvatar,
  BForm,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BFormFile,
  BCardText,
} from "bootstrap-vue";

import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
export default {
  components: {
    draggable,
    BListGroupItem,
    ValidationProvider,
    ValidationObserver,
    BAvatar,
    BForm,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BCardText,
    Prism,
    Button,
    HasError,
    AlertError,
  },
  data() {
    return {
      course_lesson_module: false,
      quiz_question_model: false,
      calss_resouce_model: false,
      course_topics: [],
      course_lessons: [],
      add_topic: {
        id: null,
        course_id: this.$route.params.id,
        name: null,
        status: true,
        is_unlock: false,
      },
      add_lesson: {
        id: null,
        course_topic_id: null,
        type: null,
        video_type: null,
        video_id: null,
        duration: null,
        question_qty: null,
        pass_percentage: null,
        name: null,
        status: true,
        is_unlock: false,
      },
      questions: {},
      resources: {},

      form: new Form({
        id: null,
        course_lesson_id: null,
        question: null,
        answer: null,
        options: [
          {
            number: "A",
            name: null,
          },
          {
            number: "B",
            name: null,
          },
          {
            number: "C",
            name: null,
          },
          {
            number: "D",
            name: null,
          },
        ],
        status: true,
      }),

      classResource: new Form({
        id: null,
        name: null,
        lesson_id: null,
        file: null,
        status: true,
      }),
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResourceModel(lesson_id) {
      this.classResource.lesson_id = lesson_id;
      this.getClassResource();
      this.calss_resouce_model = true;
    },

    async getClassResource() {
      const resources = await this.callApi(
        "get",
        "/app/course/class/resource/" + this.classResource.lesson_id
      );
      if (resources.status == 200) {
        this.resources = resources.data.resources;
      }
    },

    loadFile(e) {
      this.classResource.file = e.target.files[0];
    },

    addClassResouce() {
      this.classResource
        .post("/app/course/class/resource/store")
        .then(({ data }) => {
          this.s(data.message);
          this.getClassResource();
          this.ClearResouce();
          this.$Loading.finish();
        })
        .catch((e) => {
          this.$Loading.error();
        });
    },

    editResource(resource) {
      this.classResource.id = resource.id;
      this.classResource.name = resource.name;
      this.classResource.status = resource.status == 1 ? true : false;
    },

    ClearResouce() {
      this.classResource.id = null;
      this.classResource.name = null;
      this.classResource.file = null;
      this.classResource.status = true;
    },

    getDuration(duration) {
      let hour = duration / 60;
      if (hour > 0) {
        let day = 0;
        let ret_hour = 0;
        let ret_minute = 0;
        if (hour >= 24) {
          let hour_data = hour % 24;
          day = (hour - hour_data) / 24;

          if (hour_data >= 1) {
            let minutes = hour_data % 1;
            ret_hour = hour_data - minutes;
            ret_minute = Math.round(minutes * 60);
          } else {
            ret_minute = Math.round(hour_data * 60);
          }
        } else {
          if (hour >= 1) {
            let minutes = hour % 1;
            ret_hour = hour - minutes;
            ret_minute = Math.round(minutes * 60);
          } else {
            ret_minute = Math.round(hour * 60);
          }
        }
        return (
          (day > 0 ? day + " Day " : "") +
          ((day > 0 && ret_minute > 0) || ret_hour > 0
            ? ret_hour + " Hour "
            : "") +
          (ret_minute > 0 ? ret_minute + " Minutes" : "")
        );
      } else {
        return 0;
      }
    },
    async rearrangeTopic() {
      const course = await this.callApi(
        "post",
        "/app/course/topics/rearrange",
        { data: this.course_topics }
      );
      if (course.status == 200) {
        this.getResults();
        this.s(course.data.message);
      }
    },
    async edit(id) {
      const course_topic = await this.callApi(
        "post",
        "/app/course/topics/edit/" + id
      );
      if (course_topic.status == 200) {
        this.add_topic.id = course_topic.data.course_topic.id;
        this.add_topic.name = course_topic.data.course_topic.name;
        this.add_topic.status =
          course_topic.data.course_topic.status == true ? true : false;
        this.add_topic.is_unlock =
          course_topic.data.course_topic.is_unlock == true ? true : false;
      }
    },
    async getResults() {
      const course = await this.callApi(
        "post",
        "/app/course/topics",
        this.add_topic
      );
      if (course.status == 200) {
        this.course_topics = course.data.course_topics;
      }
    },
    add() {
      axios
        .post("/app/course/topics/store", this.add_topic)
        .then((res) => {
          this.Close();
          this.getResults();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.name)
              this.e(e.response.data.errors.name[0]);
            if (e.response.data.errors.status)
              this.e(e.response.data.errors.status[0]);
            if (e.response.data.errors.is_unlock)
              this.e(e.response.data.errors.is_unlock[0]);
          }
        });
    },

    Close() {
      this.add_topic.id = null;
      this.add_topic.name = null;
      this.add_topic.is_unlock = false;
      this.add_topic.status = true;
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },
    getLessonModel(course_topic_id) {
      this.CloseLesson();
      this.add_lesson.course_topic_id = null;
      this.course_lessons = [];

      this.add_lesson.course_topic_id = course_topic_id;
      this.getLesson(course_topic_id);
      this.course_lesson_module = true;
    },
    async getLesson(course_topic_id) {
      const course_lessons = await this.callApi(
        "post",
        "/app/course/lessons/get/" + course_topic_id
      );
      if (course_lessons.status == 200) {
        this.course_lessons = course_lessons.data.course_lessons;
      }
    },
    getQuiz(lesson_id) {
      this.questions = {};
      this.CloseQuestion();
      this.form.course_lesson_id = lesson_id;
      this.getQuestion();
      this.quiz_question_model = true;
    },
    async getQuestion() {
      const questions = await this.callApi(
        "post",
        "/app/course/question/get/" + this.form.course_lesson_id
      );
      if (questions.status == 200) {
        this.questions = questions.data.questions;
      }
    },
    addLesson() {
      axios
        .post("/app/course/lessons/store", this.add_lesson)
        .then((res) => {
          this.getLesson(res.data.course_lesson.course_topic_id);
          this.CloseLesson();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.course_topic_id)
              this.e(e.response.data.errors.course_topic_id[0]);
            if (e.response.data.errors.type)
              this.e(e.response.data.errors.type[0]);
            if (e.response.data.errors.video_id)
              this.e(e.response.data.errors.video_id[0]);
            if (e.response.data.errors.duration)
              this.e(e.response.data.errors.duration[0]);
            if (e.response.data.errors.name)
              this.e(e.response.data.errors.name[0]);
            if (e.response.data.errors.status)
              this.e(e.response.data.errors.status[0]);
            if (e.response.data.errors.is_unlock)
              this.e(e.response.data.errors.is_unlock[0]);
          }
        });
    },
    AddQuestion() {
      let url = "";
      if (this.form.id == null) {
        url = "/app/course/question/store/";
      } else {
        url = "/app/course/question/update/";
      }
      this.form
        .post(url + this.form.course_lesson_id)
        .then(({ data }) => {
          this.s(data.message);
          this.getQuestion();
          this.CloseQuestion();
          this.$Loading.finish();
        })
        .catch((e) => {
          this.$Loading.error();
        });
    },
    async editQuestion(id) {
      const question = await this.callApi(
        "post",
        "/app/course/question/edit/" + id
      );
      if (question.status == 200) {
        this.form.id = id;
        this.form.question = question.data.question.question;
        this.form.options = question.data.question.options;
        this.form.status = question.data.question.status;
        question.data.question.options.filter((data, index) => {
          if (data.is_correct) {
            this.form.answer = data.number;
          }
        });
      }
    },
    CloseQuestion() {
      this.form.id = null;
      this.form.question = null;
      this.form.answer = null;
      this.form.options = [
        {
          number: "A",
          name: null,
        },
        {
          number: "B",
          name: null,
        },
        {
          number: "C",
          name: null,
        },
        {
          number: "D",
          name: null,
        },
      ];
      this.form.status = true;
    },
    async editLesson(id) {
      const course_lesson = await this.callApi(
        "post",
        "/app/course/lessons/edit/" + id
      );
      if (course_lesson.status == 200) {
        this.add_lesson.id = course_lesson.data.course_lesson.id;
        this.add_lesson.name = course_lesson.data.course_lesson.name;
        this.add_lesson.duration = course_lesson.data.course_lesson.duration;
        this.add_lesson.video_id = course_lesson.data.course_lesson.video_id;
        this.add_lesson.type = course_lesson.data.course_lesson.type;
        this.add_lesson.pass_percentage =
          course_lesson.data.course_lesson.pass_percentage;
        this.add_lesson.question_qty =
          course_lesson.data.course_lesson.question_qty;
        this.add_lesson.video_type =
          course_lesson.data.course_lesson.video_type;
        this.add_lesson.status =
          course_lesson.data.course_lesson.status == true ? true : false;
        this.add_lesson.is_unlock =
          course_lesson.data.course_lesson.is_unlock == true ? true : false;
      }
    },
    async rearrangeLesson() {
      const course = await this.callApi(
        "post",
        "/app/course/lessons/rearrange",
        { data: this.course_lessons }
      );
      if (course.status == 200) {
        this.getLesson(this.add_lesson.course_topic_id);
        this.s(course.data.message);
      }
    },
    validationCourseLesson() {
      this.$refs.lessonValidation.validate().then((success) => {
        if (success) {
          this.addLesson();
        }
      });
    },
    CloseLesson() {
      this.add_lesson.id = null;
      this.add_lesson.name = null;
      this.add_lesson.duration = null;
      this.add_lesson.video_type = null;
      this.add_lesson.video_id = null;
      this.add_lesson.type = null;
      this.add_lesson.question_qty = null;
      this.add_lesson.pass_percentage = null;
      this.add_lesson.is_unlock = false;
      this.add_lesson.status = true;
    },
  },
  watch: {},
};
</script>

<style>
.list-group-item {
  transition: all 0.2s;
}
</style>
