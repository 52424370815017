<template>
  <div class="px-1">
    <form @submit.prevent="add" @keydown="form.onKeydown($event)">
      <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group">
            <label for="what_will_you_learn">What Will You Learn</label>
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'what_will_you_learn'
                ),
              }"
              name="what_will_you_learn"
              type="text"
              v-model="form.what_will_you_learn"
              id="what_will_you_learn"
              placeholder="Enter What Will You Learn"
            />
            <has-error :form="form" field="what_will_you_learn"></has-error>
          </div>
          <div class="form-group">
            <label for="what_will_you_learn_description"
              >What Will You Learn Description</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'what_will_you_learn_description'
                ),
              }"
              name="what_will_you_learn_description"
              type="textarea"
              :rows="3"
              v-model="form.what_will_you_learn_description"
              id="what_will_you_learn_description"
              placeholder="Enter What Will You Learn Description"
            />
            <has-error
              :form="form"
              field="what_will_you_learn_description"
            ></has-error>
          </div>
          <p
            class="bg-light"
            style="padding: 8px; margin-bottom: 6px"
            v-for="(option_learn, index) in form.what_will_you_learn_options"
            :key="index"
          >
            <span
              class="pr-1 text-danger"
              style="cursor: pointer"
              @click="removeOption(1, index)"
              ><b>X</b></span
            >
            {{ option_learn }}
          </p>
          <div class="row align-items-end mt-1">
            <div class="col-md-10">
              <label for="what_will_you_learn_options"
                >What Will You Learn Options</label
              >
              <Input
                :class="{
                  'is-invalid border-danger': form.errors.has(
                    'what_will_you_learn_options'
                  ),
                }"
                name="what_will_you_learn_options"
                type="text"
                v-model="what_will_you_learn_options"
                id="what_will_you_learn_options"
                placeholder="Enter What Will You Learn Options"
              />
            </div>
            <div class="col-md-2">
              <Button
                @click="addOption(1)"
                type="primary"
                :disabled="!what_will_you_learn_options"
                class="w-100"
                >Add</Button
              >
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="career_opportunities_title"
              >Career Opportunities Title</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'career_opportunities_title'
                ),
              }"
              name="career_opportunities_title"
              type="text"
              v-model="form.career_opportunities_title"
              id="career_opportunities_title"
              placeholder="Enter Career Opportunities Title"
            />
            <has-error
              :form="form"
              field="career_opportunities_title"
            ></has-error>
          </div>
          <div class="form-group">
            <label for="career_opportunities_description"
              >Career Opportunities Description</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'career_opportunities_description'
                ),
              }"
              name="career_opportunities_description"
              type="textarea"
              :rows="3"
              v-model="form.career_opportunities_description"
              id="career_opportunities_description"
              placeholder="Enter Career Opportunities Description"
            />
            <has-error
              :form="form"
              field="career_opportunities_description"
            ></has-error>
          </div>
          <p
            class="bg-light"
            style="padding: 8px; margin-bottom: 6px"
            v-for="(option_learn, index) in form.career_opportunities"
            :key="index"
          >
            <span
              class="pr-1 text-danger"
              style="cursor: pointer"
              @click="removeOption(2, index)"
              ><b>X</b></span
            >
            {{ option_learn }}
          </p>
          <div class="row align-items-end mt-1">
            <div class="col-md-10">
              <label for="career_opportunities">Career Opportunities</label>
              <Input
                name="career_opportunities"
                type="text"
                v-model="career_opportunities"
                id="career_opportunities"
                placeholder="Enter Career Opportunities"
              />
            </div>
            <div class="col-md-2">
              <Button
                @click="addOption(2)"
                type="primary"
                :disabled="!career_opportunities"
                class="w-100"
                >Add</Button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group">
            <label for="marketplace_title">Marketplace Section Title</label>
            <Input
              :class="{
                'is-invalid border-danger':
                  form.errors.has('marketplace_title'),
              }"
              name="marketplace_title"
              type="text"
              v-model="form.marketplace_title"
              id="marketplace_title"
              placeholder="Enter Marketplace Section Title"
            />
            <has-error :form="form" field="marketplace_title"></has-error>
          </div>
          <div class="form-group">
            <label for="marketplace_description"
              >Marketplace Section Description</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'marketplace_description'
                ),
              }"
              name="marketplace_description"
              type="textarea"
              :rows="3"
              v-model="form.marketplace_description"
              id="marketplace_description"
              placeholder="Enter Marketplace Section Description"
            />
            <has-error :form="form" field="marketplace_description"></has-error>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="certificate_title">Certificate Section Title</label>
            <Input
              :class="{
                'is-invalid border-danger':
                  form.errors.has('certificate_title'),
              }"
              name="certificate_title"
              type="text"
              v-model="form.certificate_title"
              id="certificate_title"
              placeholder="Enter Certificate Section Title"
            />
            <has-error :form="form" field="certificate_title"></has-error>
          </div>
          <div class="form-group">
            <label for="certificate_description"
              >Certificate Section Description</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'certificate_description'
                ),
              }"
              name="certificate_description"
              type="textarea"
              :rows="3"
              v-model="form.certificate_description"
              id="certificate_description"
              placeholder="Enter Certificate Section Description"
            />
            <has-error :form="form" field="certificate_description"></has-error>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="marketplace">Select Marketplace</label>
            <Select
              v-model="form.marketplace_id"
              filterable
              name="marketplace_id"
              id="marketplace"
              placeholder="Select Marketplace"
              multiple
            >
              <Option
                :value="option.id"
                v-for="(option, index) in options"
                :key="index"
                v-if="options.length && option.option_category_id == 7"
                >{{ option.name }}</Option
              >
            </Select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="resource">Select Resource</label>
            <Select
              v-model="form.resource_id"
              filterable
              name="resource_id"
              id="resource"
              placeholder="Select Resource"
              multiple
            >
              <Option
                :value="option.id"
                v-for="(option, index) in options"
                :key="index"
                v-if="options.length && option.option_category_id == 5"
                >{{ option.name }}</Option
              >
            </Select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="software">Select Software</label>
            <Select
              v-model="form.software_id"
              filterable
              name="software_id"
              id="software"
              placeholder="Select Software"
              multiple
            >
              <Option
                :value="option.id"
                v-for="(option, index) in options"
                :key="index"
                v-if="options.length && option.option_category_id == 6"
                >{{ option.name }}</Option
              >
            </Select>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="purchase_process_id">Select Purchase Process</label>
            <Select
              v-model="form.purchase_process_id"
              filterable
              name="purchase_process_id"
              id="purchase_process_id"
              placeholder="Select Purchase Process"
              multiple
            >
              <Option
                :value="option.id"
                v-for="(option, index) in options"
                :key="index"
                v-if="options.length && option.option_category_id == 8"
                >{{ option.name }}</Option
              >
            </Select>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group">
            <label for="course_for_those_title"
              >Course For Those Section Title</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'course_for_those_title'
                ),
              }"
              name="course_for_those_title"
              type="text"
              v-model="form.course_for_those_title"
              id="course_for_those_title"
              placeholder="Enter Course For Those Section Title"
            />
            <has-error :form="form" field="course_for_those_title"></has-error>
          </div>
          <div class="form-group">
            <label for="course_for_those_description"
              >Course For Those Section Description</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'course_for_those_description'
                ),
              }"
              name="course_for_those_description"
              type="textarea"
              :rows="3"
              v-model="form.course_for_those_description"
              id="course_for_those_description"
              placeholder="Enter Course For Those Section Description"
            />
            <has-error
              :form="form"
              field="course_for_those_description"
            ></has-error>
          </div>
          <p
            class="bg-light"
            style="padding: 8px; margin-bottom: 6px"
            v-for="(option_learn, index) in form.course_for_those_options"
            :key="index"
          >
            <span
              class="pr-1 text-danger"
              style="cursor: pointer"
              @click="removeOption(3, index)"
              ><b>X</b></span
            >
            {{ option_learn }}
          </p>
          <div class="row align-items-end mt-1">
            <div class="col-md-10">
              <label for="course_for_those_options"
                >Course For Those Options</label
              >
              <Input
                :class="{
                  'is-invalid border-danger': form.errors.has(
                    'course_for_those_options'
                  ),
                }"
                name="course_for_those_options"
                type="text"
                v-model="course_for_those_options"
                id="course_for_those_options"
                placeholder="Enter Course For Those Options"
              />
            </div>
            <div class="col-md-2">
              <Button
                @click="addOption(3)"
                type="primary"
                :disabled="!course_for_those_options"
                class="w-100"
                >Add</Button
              >
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="course_requirement_title"
              >Course Requirement Section Title</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'course_requirement_title'
                ),
              }"
              name="course_requirement_title"
              type="text"
              v-model="form.course_requirement_title"
              id="course_requirement_title"
              placeholder="Enter Course Requirement Section Title"
            />
            <has-error
              :form="form"
              field="course_requirement_title"
            ></has-error>
          </div>
          <div class="form-group">
            <label for="course_requirement_description"
              >Course Requirement Description</label
            >
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'course_requirement_description'
                ),
              }"
              name="course_requirement_description"
              type="textarea"
              :rows="3"
              v-model="form.course_requirement_description"
              id="course_requirement_description"
              placeholder="Enter Course Requirement Description"
            />
            <has-error
              :form="form"
              field="course_requirement_description"
            ></has-error>
          </div>
          <p
            class="bg-light"
            style="padding: 8px; margin-bottom: 6px"
            v-for="(option_learn, index) in form.course_requirement_options"
            :key="index"
          >
            <span
              class="pr-1 text-danger"
              style="cursor: pointer"
              @click="removeOption(4, index)"
              ><b>X</b></span
            >
            {{ option_learn }}
          </p>
          <div class="row align-items-end mt-1">
            <div class="col-md-10">
              <label for="course_requirement_options"
                >Course Requirement Options</label
              >
              <Input
                :class="{
                  'is-invalid border-danger': form.errors.has(
                    'course_requirement_options'
                  ),
                }"
                name="course_requirement_options"
                type="text"
                v-model="course_requirement_options"
                id="course_requirement_options"
                placeholder="Enter Course Requirement Options"
              />
            </div>
            <div class="col-md-2">
              <Button
                @click="addOption(4)"
                type="primary"
                :disabled="!course_requirement_options"
                class="w-100"
                >Add</Button
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group">
            <label for="how_join_title">How To Join Section Title</label>
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has('how_join_title'),
              }"
              name="how_join_title"
              type="text"
              v-model="form.how_join_title"
              id="how_join_title"
              placeholder="Enter How To Join Section Title"
            />
            <has-error :form="form" field="how_join_title"></has-error>
          </div>
          <div class="form-group">
            <label for="how_join_description">How Join Description</label>
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has(
                  'how_join_description'
                ),
              }"
              name="how_join_description"
              type="textarea"
              :rows="3"
              v-model="form.how_join_description"
              id="how_join_description"
              placeholder="Enter how_join_description"
            />
            <has-error :form="form" field="How Join Description"></has-error>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="meta_title">Meta Title</label>
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has('meta_title'),
              }"
              name="meta_title"
              type="text"
              v-model="form.meta_title"
              id="meta_title"
              placeholder="Enter Meta Title"
            />
            <has-error :form="form" field="meta_title"></has-error>
          </div>
          <div class="form-group">
            <label for="meta_description">Meta Description</label>
            <Input
              :class="{
                'is-invalid border-danger': form.errors.has('meta_description'),
              }"
              name="meta_description"
              type="textarea"
              :rows="3"
              v-model="form.meta_description"
              id="meta_description"
              placeholder="Enter meta_description"
            />
            <has-error :form="form" field="Meta Description"></has-error>
          </div>
        </div>
      </div>
      <div class="w-100 text-right">
        <b-button type="submit" variant="primary" :disabled="form.busy">
          UPDATE
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  vSelect,
} from "bootstrap-vue";
import { Button, HasError, AlertError } from "vform/src/components/bootstrap5";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import _ from "lodash";
import { data } from "vue-echarts";
export default {
  components: {
    HasError,
    AlertError,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
  data() {
    return {
      form: new Form({
        what_will_you_learn: null,
        what_will_you_learn_description: null,
        what_will_you_learn_options: [],
        career_opportunities_title: null,
        career_opportunities_description: null,
        career_opportunities: [],
        marketplace_title: null,
        marketplace_description: null,
        certificate_title: null,
        certificate_description: null,
        course_for_those_title: null,
        course_for_those_description: null,
        course_for_those_options: [],
        course_requirement_title: null,
        course_requirement_description: null,
        course_requirement_options: [],
        how_join_title: null,
        how_join_description: null,
        meta_title: null,
        meta_description: null,
        option_id: [],
        marketplace_id: [],
        software_id: [],
        resource_id: [],
        purchase_process_id: [],
      }),
      what_will_you_learn_options: null,
      career_opportunities: null,
      course_for_those_options: null,
      course_requirement_options: null,
    };
  },
  mounted() {
    this.$store.dispatch("getCourseOptions");
    this.getResult();
  },

  methods: {
    async getResult() {
      const profile = await this.callApi(
        "get",
        "/app/course/profile/" + this.$route.params.id
      );
      if (profile.status == 200) {
        this.form.what_will_you_learn =
          profile.data.profile.what_will_you_learn;
        this.form.what_will_you_learn_description =
          profile.data.profile.what_will_you_learn_description;
        this.form.what_will_you_learn_options =
          profile.data.profile.what_learn_options;

        this.form.career_opportunities_title =
          profile.data.profile.career_opportunities_title;
        this.form.career_opportunities_description =
          profile.data.profile.career_opportunities_description;
        this.form.career_opportunities =
          profile.data.profile.opportunity_options;

        this.form.marketplace_title = profile.data.profile.marketplace_title;
        this.form.marketplace_description =
          profile.data.profile.marketplace_description;

        this.form.certificate_title = profile.data.profile.certificate_title;
        this.form.certificate_description =
          profile.data.profile.certificate_description;

        this.form.course_for_those_title =
          profile.data.profile.course_for_those_title;
        this.form.course_for_those_description =
          profile.data.profile.course_for_those_description;
        this.form.course_for_those_options =
          profile.data.profile.course_those_options;

        this.form.course_requirement_title =
          profile.data.profile.course_requirement_title;
        this.form.course_requirement_description =
          profile.data.profile.course_requirement_description;
        this.form.course_requirement_options =
          profile.data.profile.requirement_options;

        this.form.how_join_title = profile.data.profile.how_join_title;
        this.form.how_join_description =
          profile.data.profile.how_join_description;

        this.form.meta_title = profile.data.profile.meta_title;
        this.form.meta_description = profile.data.profile.meta_description;

        this.form.marketplace_id = profile.data.option_id;
        this.form.software_id = profile.data.option_id;
        this.form.resource_id = profile.data.option_id;
        this.form.purchase_process_id = profile.data.option_id;
      }
    },
    addOption(type) {
      if (type == 1) {
        this.form.what_will_you_learn_options.push(
          this.what_will_you_learn_options
        );
        this.what_will_you_learn_options = null;
      }
      if (type == 2) {
        this.form.career_opportunities.push(this.career_opportunities);
        this.career_opportunities = null;
      }
      if (type == 3) {
        this.form.course_for_those_options.push(this.course_for_those_options);
        this.course_for_those_options = null;
      }
      if (type == 4) {
        this.form.course_requirement_options.push(
          this.course_requirement_options
        );
        this.course_requirement_options = null;
      }
    },
    removeOption(type, index) {
      if (type == 1) {
        this.form.what_will_you_learn_options.splice(index, 1);
      }
      if (type == 2) {
        this.form.career_opportunities.splice(index, 1);
      }
      if (type == 3) {
        this.form.course_for_those_options.splice(index, 1);
      }
      if (type == 4) {
        this.form.course_requirement_options.splice(index, 1);
      }
    },
    optionManage() {
      this.form.option_id = [];
      this.form.marketplace_id.forEach((data) => {
        this.form.option_id.push(data);
      });
      this.form.software_id.forEach((data) => {
        this.form.option_id.push(data);
      });
      this.form.resource_id.forEach((data) => {
        this.form.option_id.push(data);
      });
      this.form.purchase_process_id.forEach((data) => {
        this.form.option_id.push(data);
      });
    },
    add() {
      this.optionManage();
      this.form
        .post("/app/course/profile/" + this.$route.params.id)
        .then(({ data }) => {
          this.s(data.message);
          this.$Loading.finish();
        })
        .catch((e) => {
          this.$Loading.error();
        });
    },
  },
  computed: {
    options() {
      return this.$store.getters.getCourseOptions;
    },
  },
};
</script>

<style></style>
