<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <!-- $store.state.base_url + 'images/CIT-13-Years.jpg' -->
        <div class="border rounded p-2 mb-2">
          <b-media
            no-body
            vertical-align="center"
            class="flex-column flex-md-row align-items-center"
          >
            <b-media-aside>
              <b-img
                :src="course_from.image"
                height="174"
                width="310"
                class="rounded mr-2 mb-1 mb-md-0"
              />
            </b-media-aside>
            <b-media-body>
              <b-form-group label="Video Image" label-for="image">
                <b-form-file
                  id="image"
                  accept=".jpg, .png, .gif"
                  @change="loadImage($event)"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />
              </b-form-group>
              <!-- email -->
              <div class="row">
                <div class="col-md-6">
                  <b-form-group label="Video ID" label-for="video_id">
                    <validation-provider
                      #default="{ errors }"
                      name="Video Id"
                      rules="required"
                    >
                      <Input
                        name="video_id"
                        placeholder="Enter Your Video ID"
                        type="text"
                        id="video_id"
                        v-model="course_from.video_id"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group label="Video Type" label-for="video_id">
                    <validation-provider
                      #default="{ errors }"
                      name="video_type"
                      rules="required"
                    >
                      <Select v-model="course_from.video_type" filterable>
                        <Option :value="1">Vimeo Video</Option>
                        <Option :value="2">Youtube Video</Option>
                      </Select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
              <div class="text-right">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mt-2"
                  @click="validationForm"
                >
                  Update
                </b-button>
              </div>
            </b-media-body>
          </b-media>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  vSelect,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  props: ["course_from"],
  data() {
    return {
      image: null,
    };
  },
  methods: {
    loadImage(e) {
      this.image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.course_from.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Update() {
      const fd = new FormData();
      if (this.image) {
        fd.append("image", this.image, this.image.name);
      }
      fd.append("video_id", this.course_from.video_id);
      fd.append("video_type", this.course_from.video_type);

      axios
        .post("/app/course/media/information/" + this.course_from.id, fd)
        .then((res) => {
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.image)
              this.e(e.response.data.errors.image[0]);
            if (e.response.data.errors.video_id)
              this.e(e.response.data.errors.video_id[0]);
            if (e.response.data.errors.video_type)
              this.e(e.response.data.errors.video_type[0]);
          }
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },
  components: {
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    vSelect,
  },
};
</script>

<style></style>
