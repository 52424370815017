<template>
  <div class="px-1">
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <div class="row mb-2">
          <div class="col-md-6">
            <b-form-group label="Select Category" label-for="category_id">
              <validation-provider
                #default="{ errors }"
                name="Select Category"
                rules="required"
              >
                <Select
                  v-model="course_from.category_id"
                  filterable
                  name="category_id"
                  id="category_id"
                  placeholder="Select Category"
                >
                  <Option
                    :value="category.id"
                    v-for="(category, index) in categories"
                    :key="index"
                    v-if="categories.length"
                    >{{ category.name }}</Option
                  >
                </Select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Select Teacher" label-for="teacher_id">
              <validation-provider #default="{ errors }" name="Select Teacher">
                <Select
                  v-model="course_from.teacher_id"
                  filterable
                  :clearable="true"
                  name="teacher_id"
                  id="teacher_id"
                  placeholder="Select Teacher"
                >
                  <Option
                    :value="teacher.id"
                    v-for="(teacher, index) in teachers"
                    :key="index"
                    v-if="teachers.length"
                    >{{ teacher.name }}</Option
                  >
                </Select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Course Name" label-for="name">
              <validation-provider
                #default="{ errors }"
                name="Course Name"
                rules="required"
              >
                <Input
                  name="name"
                  type="text"
                  v-model="course_from.name"
                  id="name"
                  placeholder="Enter Course Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Course Slug" label-for="slug">
              <validation-provider
                #default="{ errors }"
                name="Course Slug"
                rules="required"
              >
                <Input
                  name="slug"
                  type="text"
                  v-model="course_from.slug"
                  id="slug"
                  placeholder="Enter Course Slug"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Course Fee" label-for="course_fee">
              <validation-provider
                #default="{ errors }"
                name="Course Fee"
                rules="required"
              >
                <InputNumber
                  v-model="course_from.course_fee"
                  :min="0"
                  class="w-100"
                  id="course_fee"
                  name="course_fee"
                  placeholder="Enter Course Fee"
                ></InputNumber>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Course Discount" label-for="discount">
              <validation-provider #default="{ errors }" name="Course Discount">
                <InputNumber
                  v-model="course_from.discount"
                  :min="0"
                  :max="course_from.course_fee"
                  class="w-100"
                  id="discount"
                  name="discount"
                  placeholder="Enter Course Discount"
                ></InputNumber>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group
              label="Teacher Commission (%)"
              label-for="teacher_commission"
            >
              <validation-provider
                #default="{ errors }"
                name="Teacher Commission"
              >
                <InputNumber
                  v-model="course_from.teacher_commission"
                  :min="0"
                  class="w-100"
                  id="teacher_commission"
                  name="teacher_commission"
                  placeholder="Enter Teacher Commission"
                ></InputNumber>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group
              label="Affiliate Commission (%)"
              label-for="affiliate_commission"
            >
              <validation-provider
                #default="{ errors }"
                name="Affiliate Commission"
              >
                <InputNumber
                  v-model="course_from.affiliate_commission"
                  :min="0"
                  class="w-100"
                  id="affiliate_commission"
                  name="affiliate_commission"
                  placeholder="Enter Affiliate Commission"
                ></InputNumber>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Course Description" label-for="description">
              <validation-provider
                #default="{ errors }"
                name="Course Description"
              >
                <Input
                  v-model="course_from.description"
                  name="description"
                  id="description"
                  placeholder="Course Description"
                  type="textarea"
                  :autosize="{ minRows: 8, maxRows: 8 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <b-form-group
                  label="Course Duration (Minutes)"
                  label-for="duration"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Course Duration"
                  >
                    <InputNumber
                      v-model="course_from.duration"
                      :min="0"
                      class="w-100"
                      id="duration"
                      name="duration"
                      placeholder="Enter Course Duration"
                    ></InputNumber>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Select Course Level" label-for="level">
                  <validation-provider
                    #default="{ errors }"
                    name="Select Level"
                    rules="required"
                  >
                    <Select
                      v-model="course_from.level"
                      filterable
                      name="level"
                      id="level"
                      placeholder="Select Course Level"
                    >
                      <Option :value="1">Beginner</Option>
                      <Option :value="2">Intermediate</Option>
                      <Option :value="3">Advanced</Option>
                    </Select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Select Course Type" label-for="type">
                  <validation-provider
                    #default="{ errors }"
                    name="Select Type"
                    rules="required"
                  >
                    <Select
                      v-model="course_from.type"
                      filterable
                      name="type"
                      id="type"
                      placeholder="Select Course Type"
                    >
                      <Option :value="1">Paid</Option>
                      <Option :value="2">Free</Option>
                      <Option :value="3">Special Offer</Option>
                    </Select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  label="Select Course Activities"
                  label-for="activities"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Select Course Activities"
                  >
                    <Select
                      v-model="course_from.activities"
                      filterable
                      name="activities"
                      id="activities"
                      placeholder="Select Course Activities"
                    >
                      <Option :value="1">Popular</Option>
                      <Option :value="2">Trendy</Option>
                      <Option :value="3">New</Option>
                      <Option :value="4">Trendy High Demand</Option>
                    </Select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Course Review" label-for="review">
                  <validation-provider
                    #default="{ errors }"
                    name="Course Review"
                  >
                    <Input
                      name="review"
                      type="text"
                      v-model="course_from.review"
                      id="review"
                      placeholder="Enter Course Review"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Course Rating" label-for="rating">
                  <validation-provider
                    #default="{ errors }"
                    name="Course Rating"
                  >
                    <InputNumber
                      v-model="course_from.rating"
                      :min="0"
                      :max="5"
                      class="w-100"
                      id="rating"
                      name="rating"
                      placeholder="Enter Course Rating"
                    ></InputNumber>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div
              class="row"
              v-for="(
                course_details_array, index
              ) in course_from.course_details_array"
              :key="index"
              v-if="course_from.course_details_array.length"
            >
              <div class="col-lg-10 col-md-9">
                <b-form-group>
                  <Input
                    name="course_details_array"
                    type="text"
                    v-model="course_details_array.data"
                    id="course_details_array"
                    placeholder="Enter Course Details"
                  />
                </b-form-group>
              </div>
              <div class="col-lg-2 col-md-3">
                <button
                  style="padding: 6px"
                  class="btn btn-primary w-100"
                  @click="addDetails()"
                  v-if="index + 1 == course_from.course_details_array.length"
                >
                  <feather-icon icon="PlusCircleIcon" size="22" />
                </button>
                <button
                  style="padding: 6px"
                  class="btn btn-danger w-100"
                  v-else
                  @click="removeDetails(index)"
                >
                  <feather-icon icon="DeleteIcon" size="22" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <b-form-checkbox
          name="status"
          style="margin-top: 5px"
          v-model="course_from.status"
          switch
          inline
        >
          Status
        </b-form-checkbox>
        <b-form-checkbox
          name="is_show"
          style="margin-top: 5px"
          v-model="course_from.is_show"
          switch
          inline
        >
          Is Show
        </b-form-checkbox>
        <b-button
          type="submit"
          class="float-right"
          variant="primary"
          @click="validationForm"
        >
          Update
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  props: ["course_from"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    addDetails() {
      this.course_from.course_details_array.push({
        data: null,
      });
    },
    removeDetails(index) {
      this.course_from.course_details_array.splice(index, 1);
    },
    Update() {
      axios
        .post(
          "/app/course/basic/information/" + this.course_from.id,
          this.course_from
        )
        .then((res) => {
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.category_id)
              this.e(e.response.data.errors.category_id[0]);
            if (e.response.data.errors.teacher_id)
              this.e(e.response.data.errors.teacher_id[0]);
            if (e.response.data.errors.slug)
              this.e(e.response.data.errors.slug[0]);
            if (e.response.data.errors.name)
              this.e(e.response.data.errors.name[0]);
            if (e.response.data.errors.course_fee)
              this.e(e.response.data.errors.course_fee[0]);
            if (e.response.data.errors.discount)
              this.e(e.response.data.errors.discount[0]);
            if (e.response.data.errors.teacher_commission)
              this.e(e.response.data.errors.teacher_commission[0]);
            if (e.response.data.errors.affiliate_commission)
              this.e(e.response.data.errors.affiliate_commission[0]);
            if (e.response.data.errors.level)
              this.e(e.response.data.errors.level[0]);
            if (e.response.data.errors.type)
              this.e(e.response.data.errors.type[0]);
            if (e.response.data.errors.activities)
              this.e(e.response.data.errors.activities[0]);
            if (e.response.data.errors.status)
              this.e(e.response.data.errors.status[0]);
            if (e.response.data.errors.is_show)
              this.e(e.response.data.errors.is_show[0]);
            if (e.response.data.errors.duration)
              this.e(e.response.data.errors.duration[0]);
            if (e.response.data.errors.review)
              this.e(e.response.data.errors.review[0]);
            if (e.response.data.errors.rating)
              this.e(e.response.data.errors.rating[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
          }
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
  computed: {
    categories() {
      return this.$store.getters.getSubCategory;
    },
    teachers() {
      return this.$store.getters.getTeacher;
    },
  },
};
</script>

<style></style>
